<template>
  <!-- 部门管理 -->
  <div class="container">
    <div class="head">
      <div class="dataOper">
        <div class="OperButton">
          <router-link to="/setting/shipper/add">
            <div class="addBtn">
              <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column
            prop="shipper"
            label="发货人"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="mobile" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="adddress" label="发货地址" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <div class="operating">
                <router-link to="/setting/shipper/edit"></router-link>
                <div class="edit" @click="shipperEdit(scope.row)">
                <img style="width: 18px;height: 18px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="shipperDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import { shipperList, shipperDel } from "@/api/shipper";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      tableData: [],
      queryinfo: { currPage: "" },
      total: null,
    };
  },
  activated() {
    this.list();
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //地址列表
    list() {
      shipperList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },
    //分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    //编辑相亲
    shipperEdit(val) {
      this.$router.push({
        path: "/setting/shipper/edit",
        query: {
          id: val.id,
          adddress: val.adddress,
          mobile: val.mobile,
          shipper: val.shipper,
        },
      });
    },
    //删除地址
    async shipperDel(id) {
      if (!(await this._box())) return;
      shipperDel({ id: id }).then((res) => {
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage =
          this.queryinfo.currPage > totalPage
            ? totalPage
            : this.queryinfo.currPage;
        this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
  },
};
</script>
<style  lang="less" scoped>
@import "../../../assets/css/common.less";
.dataOper{
  padding: 20px;
  padding-left: 0px;
}
.head{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
</style>
